<template>
  <header class="header" id="header">
    <div class="header_toggle" @click="onToggleSidebar">
      <i class="bx bx-menu" id="header-toggle"></i>
    </div>
    <div class="header_img">
      <img
        class="img-fluid mx-auto"
        src="../assets/imgs/IMG_28532.4cb8cbd4.jpg"
        alt=""
      />
      <!-- <div class="position-absolute blue-square"></div> -->
    </div>
  </header>
  <div class="l-navbar" id="nav-bar">
    <nav class="nav">
      <div>
        <a
          href="#"
          class="nav_logo"
          @click="scrollToLink('#Hero')"
          data-value="#Hero"
        >
          <img
            src="../assets/imgs/Importspark-NoBG.9976916a.svg"
            width="25"
            alt=""
          />
          <span class="nav_logo-name">Samexplains</span>
        </a>
        <div class="nav_list">
          <a
            href="#"
            class="nav_link"
            @click="scrollToLink('#About')"
            data-value="#About"
          >
            <i class="bx bx-user nav_icon"></i>
            <span class="nav_name">About</span>
          </a>
          <a
            href="#"
            class="nav_link"
            @click="scrollToLink('#Projects')"
            data-value="#Projects"
          >
            <i class="bx bx-grid-alt nav_icon"></i>
            <span class="nav_name">Portfolio</span>
          </a>
          <a
            href="#"
            class="nav_link"
            @click="scrollToLink('#Contact')"
            data-value="#Contact"
          >
            <i class="bx bx-message-square-detail nav_icon"></i>
            <span class="nav_name">Contact</span>
          </a>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import $ from "jquery/dist/jquery.min";
export default {
  data() {
    return {
      toggleId: "header-toggle",
      navId: "nav-bar",
      bodyId: "body-pd",
      headerId: "header",
    };
  },
  methods: {
    onToggleSidebar() {
      const toggle = document.getElementById(this.toggleId),
        nav = document.getElementById(this.navId),
        bodypd = document.getElementById(this.bodyId),
        headerpd = document.getElementById(this.headerId);

      // show navbar
      nav.classList.toggle("show");
      // change icon
      toggle.classList.toggle("bx-x");
      // add padding to body
      bodypd.classList.toggle("body-pd");
      // add padding to header
      headerpd.classList.toggle("body-pd");
      console.warn("click");
    },
    scrollToLink(value) {
      const scrollTo = $(value);
      const _offset = scrollTo.first().offset().top - 100;
      $("html, body").animate(
        {
          scrollTop: _offset,
        },
        500
      );
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");
</style>

