<template>
  <div class="row">
    <div class="offset-lg-5 col-lg-7 col-12">
      <section id="About">
        <h4 class="fw-bolder fs-2">A little about myself</h4>
        <article class="mt-4 row">
          <div class="col-4">
            <img
              src="../assets/imgs/IMG_28532.4cb8cbd4.jpg"
              class="c img-small img-fluid box-shadow p-1 about-img"
              alt=""
            />
          </div>
          <div class="col-8 text-wrap d-lg-inline-block">
            <h5 class="fs-4">Fullstack Developer</h5>
            <p class="fw-light fs-6 font-l">
              Constantly learning and exploring new technologies.
            </p>
            <a href="https://www.linkedin.com/in/samuel-hue" target="_blank">
              <i class="bx bxl-linkedin-square social-icon"></i>
            </a>
            <a href="https://github.com/Sampuccino" target="_blank">
              <i class="bx bxl-github social-icon"></i>
            </a>
          </div>
        </article>
      </section>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
#About {
  margin-top: 25rem;
  text-align: left;
}
.about-img {
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  object-position: center;
}

.font-l {
  font-family: "Open Sans Regular";
}
.social-icon {
  color: white;
  font-size: 1.5rem;
  background-color: black;
  padding: 1rem;
  transition: all 0.25s ease-in-out;
}

.social-icon:hover {
  transition: all 0.25s ease-in-out;
  background-color: var(--first-color);
}
</style>