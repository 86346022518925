<template>
  <div class="widescreen-container position-relative" id="Hero">
    <img
      src="../assets/imgs/pexels-adriaan-greyling-751820.jpg"
      class="img-fluid widescreen-image"
      alt=""
    />
    <div class="position-absolute hero-message-box box-shadow">
      <div>
        Howdy, <br />
        allow me to explain.
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.widescreen-container {
  margin-bottom: -15.55rem;
}
.widescreen-image {
  min-width: 100%;
  max-height: 75%;
  object-position: center;
  object-fit: cover;
  filter: grayscale(0.85);
}

.hero-message-box {
  background-color: var(--first-color);
  color: var(--white-color);
  left: 0;
  margin-top: -15rem;
  text-align: left;
  padding: 8rem;
}

.hero-message-box div {
  font-size: 2.5rem;
  font-family: "Open Sans Bold";
  /* line-height: 3rem; */
}
</style>