<template>
  <body id="body-pd">
    <navbar />
    <!--Container Main start-->
    <div class="height-100 d-flex flex-column">
      <hero class="bg-light" />
      <about />
      <portfolio class="bg-light" />

      <div class="email-box" id="Contact">
        <div class="row">
          <div class="col-12 p-5 os-eb">
            <span class="os-light"
              >Have any questions or would like to get in touch You can reach me
              at</span
            >
            <br />
            samexplains@protonmail.com
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "boxicons/css/boxicons.min.css";
import "boxicons";
import Navbar from "./components/Navbar.vue";
import Hero from "./components/Hero.vue";
import About from "./components/About.vue";
import Portfolio from "./components/Portfolio.vue";
export default {
  name: "App",
  components: { Navbar, Hero, About, Portfolio },
};
</script>
<style>
@import "./assets/css/style.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
}
.navbar {
  background-color: #7a19f1;
}
</style>
